import { useReactiveVar } from '@apollo/client';
import { Group, LoadingOverlay, Paper, Stack, UnstyledButton } from '@mantine/core';
import { IconCurrencyEuro } from '@tabler/icons-react';
import { Form, Formik } from 'formik';
import _omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconRadioButtonOff from '@/assets/icons/IconRadioButtonOff.svg?react';
import IconRadioButtonOn from '@/assets/icons/IconRadioButtonOn.svg?react';
import { Button } from '@/components/ButtonVariants';
import { FormikNumberInput, FormikSelect } from '@/components/FormikWrapped';
import FormikTextareaInput from '@/components/FormikWrapped/FormikTextareaInput';
import FormikModalSelect from '@/components/FormikWrapped/new/FormikModalSelect';
import { i18n } from '@/components/I18N';
import { showErrorToast } from '@/components/Toast';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import {
  GetItemsDocument,
  useCreateItemMutation,
  useTaxExemptionReasonsLazyQuery,
  useUpdateItemMutation,
} from '@/graphql-operations';
import {
  Item_Type,
  Item_Unit_Type,
  Item_Vat_Rate,
  // Item,
  ItemInput,
  MutationCreateItemArgs,
  Tenant_Pt_Territory,
} from '@/graphql-types';
import { cn, convertPriceToFromCents } from '@/utils';
import { getTaxRate, getVatRateOptions } from '@/utils/getTaxOrVatRate';
import {
  getVatExemptionReasonExtendedOptions,
  getVatExemptionReasonOptions,
} from '@/utils/getVatExemptionReasonOptions';
import { scrollToErrors } from '@/utils/scrollToErrors';

import WithholdingTaxForm from '../WithholdingTax';
// import _capitalize from 'lodash/capitalize';
import validationSchema from './validationSchema';

const DEFAULT_VATRATE = Item_Vat_Rate.Normal;

const DEFAULT_VATEXEMPTION_REASON = 'M01';

type CreateNewItemFormProps = {
  defaultFormValues?: MutationCreateItemArgs['item'];
  style?: React.CSSProperties;
  onSuccess?: (newItem: ItemInput) => void;
  /** Pass together with updateItemId */
  isEditing?: boolean;
  updateItemId?: string;
  allowQuantityField?: boolean;
  isUpdatingInvoiceItem?: boolean;
  disabledFields?: {
    name?: boolean;
    taxRate?: boolean;
    unitType?: boolean;
    vatRate?: boolean;
    type?: boolean;
    vatExemptionReason?: boolean;
    quantity?: boolean;
    withholdingTaxAvailable?: boolean;
    withholdingTaxPercent?: boolean;
  };
  shouldSaveToDB?: boolean;
};

const CreateNewItemForm = ({
  defaultFormValues,
  style,
  onSuccess,
  isEditing,
  updateItemId,
  allowQuantityField = false,
  isUpdatingInvoiceItem,
  disabledFields = {},
  shouldSaveToDB,
}: CreateNewItemFormProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);

  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);

  const [createItemMutation, { loading }] = useCreateItemMutation({
    refetchQueries: [GetItemsDocument],
    onCompleted(data) {
      if (onSuccess) {
        onSuccess(data.createItem);
      }
    },
    onError: (error) => {
      showErrorToast({ title: t('error.createItem'), message: error.message });
      return;
    },
  });

  const [updateItemMutation, { loading: updateItemMutationLoading }] = useUpdateItemMutation({
    refetchQueries: [GetItemsDocument],
    onCompleted(data) {
      if (onSuccess) {
        onSuccess(data.updateItem);
      }
    },
    onError: (error) => {
      showErrorToast({ title: t('error.updateItem'), message: error.message });
      return;
    },
  });

  const [fetchTaxExemptionReasons, { data: taxExemptionReasons, loading: loadingTaxExemptionReasons }] =
    useTaxExemptionReasonsLazyQuery();

  useEffect(() => {
    if (isEditing && defaultFormValues?.vatRate && defaultFormValues?.type) {
      fetchTaxExemptionReasons({
        variables: {
          query: {
            type: defaultFormValues.type,
            vatRate: defaultFormValues.vatRate,
          },
        },
      });
    }
  }, [isEditing, defaultFormValues, fetchTaxExemptionReasons]);

  const zeroOrEmpty = (value: number | null | undefined) => {
    if (typeof value === 'undefined') {
      return undefined;
    }

    if (typeof value === 'number') {
      return value;
    }

    return undefined;
  };

  const territory = tenantObject?.territory || Tenant_Pt_Territory.Continental;

  const DEFAULT_TYPE =
    tenantObject?.sellsGoods && !tenantObject?.providesServices ? Item_Type.Product : Item_Type.Service;

  const initialValues = {
    name: defaultFormValues?.name || '',
    type: defaultFormValues?.type || DEFAULT_TYPE,
    unitPrice: defaultFormValues?.unitPrice,
    unitType: defaultFormValues?.unitType || Item_Unit_Type.Unit,
    taxRate: zeroOrEmpty(defaultFormValues?.taxRate),
    vatRate: defaultFormValues?.vatRate || DEFAULT_VATRATE,
    vatExemptionReason: defaultFormValues?.vatExemptionReason || DEFAULT_VATEXEMPTION_REASON,
    withholdingTaxAvailable: defaultFormValues?.withholdingTaxAvailable || false,
    withholdingTaxPercent: defaultFormValues?.withholdingTaxPercent,
    withholdingTaxReason: defaultFormValues?.withholdingTaxReason,
    withholdingTaxType: defaultFormValues?.withholdingTaxType,
    // @ts-ignore
    ...(allowQuantityField ? { quantity: defaultFormValues?.quantity || 1 } : {}), // TODO: will be fixed once BE is updated
  };

  const handleFormSubmit = (values: MutationCreateItemArgs['item']) => {
    const trimmedValues = {
      ...values,
      name: values.name?.trim() || '-',
    };

    // TODO: using "as ItemInput" as lodash.omit wraps the type into Partial...
    const tempValues = {
      ..._omit(trimmedValues, ['__typename', 'quantity']),
      unitPrice: convertPriceToFromCents(trimmedValues.unitPrice!, false),
      taxRate: getTaxRate(territory, trimmedValues.vatRate!),
    } as ItemInput;

    if (tempValues.vatRate !== Item_Vat_Rate.Exempt && tempValues.vatRate !== Item_Vat_Rate.Reversecharge) {
      delete tempValues.vatExemptionReason;
    }

    const isWithholdingTaxAvailable = tempValues?.withholdingTaxAvailable || false;

    if (!isWithholdingTaxAvailable) {
      tempValues.withholdingTaxAvailable = false;
      // @ts-expect-error until we support null on the graphql schema to delete field values
      tempValues.withholdingTaxPercent = null;
      // @ts-expect-error until we support null on the graphql schema to delete field values
      tempValues.withholdingTaxReason = null;
      // @ts-expect-error until we support null on the graphql schema to delete field values
      tempValues.withholdingTaxType = null;
    }

    if (isUpdatingInvoiceItem && updateItemId) {
      const updatedInvoiceItem = {
        id: updateItemId,
        name: tempValues.name,
        type: tempValues.type,
        unitPrice: tempValues.unitPrice,
        unitType: tempValues.unitType,
        taxRate: tempValues.taxRate,
        vatRate: tempValues.vatRate,
        vatExemptionReason: tempValues.vatExemptionReason,
        withholdingTaxAvailable: tempValues.withholdingTaxAvailable,
        withholdingTaxPercent: tempValues.withholdingTaxPercent,
        withholdingTaxReason: tempValues.withholdingTaxReason,
        withholdingTaxType: tempValues.withholdingTaxType,
      };

      if (onSuccess) {
        onSuccess(updatedInvoiceItem);
      }

      return;
    }

    if (isEditing && updateItemId) {
      updateItemMutation({ variables: { updateItemId, item: tempValues } });
      return;
    }

    if (shouldSaveToDB) {
      createItemMutation({ variables: { item: tempValues } });
    } else {
      if (onSuccess) {
        onSuccess(tempValues);
      }
    }
  };

  return (
    <Paper
      p={0}
      shadow="none"
      style={{
        ...style,
        position: 'relative',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={handleFormSubmit}
        validateOnBlur={isFirstSubmitAttempt}
        validateOnChange={isFirstSubmitAttempt}
      >
        {({ errors, touched, values, setFieldValue, validateForm }) => {
          const unitTypeData =
            values.type === Item_Type.Service
              ? [
                  { value: Item_Unit_Type.Unit, label: t('label.unit') },
                  { value: Item_Unit_Type.Hour, label: t('label.hour') },
                  { value: Item_Unit_Type.Day, label: t('label.day') },
                  { value: Item_Unit_Type.Week, label: t('label.week') },
                  { value: Item_Unit_Type.Month, label: t('label.month') },
                ]
              : [
                  { value: Item_Unit_Type.Unit, label: t('label.unit') },
                  { value: Item_Unit_Type.Kilogram, label: t('label.kilogram') },
                  { value: Item_Unit_Type.Liter, label: t('label.liter') },
                  { value: Item_Unit_Type.Meter, label: t('label.meter') },
                  { value: Item_Unit_Type.SquareMeter, label: t('label.squareMeter') },
                  { value: Item_Unit_Type.CubicMeter, label: t('label.cubicMeter') },
                  { value: Item_Unit_Type.Box, label: t('label.box') },
                  { value: Item_Unit_Type.Package, label: t('label.package') },
                ];

          const productTypeButtons = [
            <UnstyledButton
              onClick={() => {
                setFieldValue('type', Item_Type.Service);
                setFieldValue('unitType', Item_Unit_Type.Unit);
                if (values.vatRate === Item_Vat_Rate.Exempt || values.vatRate === Item_Vat_Rate.Reversecharge) {
                  setFieldValue('vatExemptionReason', null);
                  fetchTaxExemptionReasons({
                    variables: {
                      query: {
                        type: Item_Type.Service,
                        vatRate: values.vatRate,
                      },
                    },
                  });
                }
              }}
              className={cn(
                'w-full rounded-[20px] px-4 py-[14px] outline',
                {
                  'outline-1 outline-dark-braun': values.type !== Item_Type.Service,
                },
                values.type === Item_Type.Service
                  ? disabledFields.type
                    ? 'outline-2 outline-[#e4e4e4]'
                    : 'outline-2 outline-moroccan-day'
                  : ''
              )}
              disabled={disabledFields.type}
            >
              <div className="flex items-center">
                {values.type === Item_Type.Service ? (
                  <IconRadioButtonOn
                    className={cn(
                      values.type === Item_Type.Service
                        ? disabledFields.type
                          ? 'fill-[#e4e4e4]'
                          : 'fill-moroccan-blue'
                        : ''
                    )}
                  />
                ) : (
                  <IconRadioButtonOff />
                )}
                <div className="ml-3 flex flex-col">
                  <span className="text-[17px] font-normal">{t('label.service')}</span>
                </div>
              </div>
            </UnstyledButton>,
            <UnstyledButton
              onClick={() => {
                setFieldValue('type', Item_Type.Product);
                setFieldValue('unitType', Item_Unit_Type.Unit);
                if (values.vatRate === Item_Vat_Rate.Exempt || values.vatRate === Item_Vat_Rate.Reversecharge) {
                  setFieldValue('vatExemptionReason', null);
                  fetchTaxExemptionReasons({
                    variables: {
                      query: {
                        type: Item_Type.Product,
                        vatRate: values.vatRate,
                      },
                    },
                  });
                }
              }}
              className={cn(
                'w-full rounded-[20px] px-4 py-[14px] outline',
                {
                  'outline-1 outline-dark-braun': values.type !== Item_Type.Product,
                },
                values.type === Item_Type.Product
                  ? disabledFields.type
                    ? 'outline-2 outline-[#e4e4e4]'
                    : 'outline-2 outline-moroccan-day'
                  : ''
              )}
              disabled={disabledFields.type}
            >
              <div className="flex items-center">
                {values.type === Item_Type.Product ? (
                  <IconRadioButtonOn
                    className={cn(
                      values.type === Item_Type.Product
                        ? disabledFields.type
                          ? 'fill-[#e4e4e4]'
                          : 'fill-moroccan-blue'
                        : ''
                    )}
                  />
                ) : (
                  <IconRadioButtonOff />
                )}
                <div className="ml-3 flex flex-col">
                  <span className="text-[17px] font-normal">{t('label.goods')}</span>
                </div>
              </div>
            </UnstyledButton>,
          ];

          return (
            <Form autoComplete="off">
              <LoadingOverlay visible={loading || updateItemMutationLoading || loadingTaxExemptionReasons} />
              <Stack gap={12}>
                <div className="flex flex-row space-x-2 pt-1">
                  {DEFAULT_TYPE === Item_Type.Product ? productTypeButtons.reverse() : productTypeButtons}
                </div>

                <FormikTextareaInput
                  id="name"
                  name="name"
                  placeholder={
                    values.type === Item_Type.Service
                      ? t('placeholder.descriptionForService')
                      : t('placeholder.descriptionForGoods')
                  }
                  label={
                    values.type === Item_Type.Service
                      ? t('label.descriptionForService')
                      : t('label.descriptionForGoods')
                  }
                  error={errors.name && touched.name ? errors.name : null}
                  disabled={disabledFields.name}
                />

                <FormikNumberInput
                  id="unitPrice"
                  name="unitPrice"
                  rightSection={
                    values.unitPrice || values.unitPrice === 0 ? (
                      <div className="pr-2.5 pt-[17px]">
                        <IconCurrencyEuro size={16} stroke={1.5} />
                      </div>
                    ) : null
                  }
                  // label={t('placeholder.unitPrice')}
                  label={t('label.unitPrice') + ' ' + t('label.withoutVat')}
                  placeholder={t('label.unitPrice') + ' ' + t('label.withoutVat')}
                  error={errors.unitPrice && touched.unitPrice ? errors.unitPrice : null}
                  decimalSeparator=","
                  thousandSeparator="."
                  min={0}
                  inputMode="decimal"
                  allowNegative={false}
                  hideControls
                  decimalScale={2}
                  // fixedDecimalScale
                />

                <FormikSelect
                  size="md"
                  name="unitType"
                  disabled={disabledFields.unitType}
                  data={unitTypeData}
                  label={t('label.unitOfMeasurement')}
                  placeholder={t('placeholder.unitOfMeasurement')}
                  error={errors.unitType && touched.unitType ? errors.unitType : null}
                />

                <Group grow align="start">
                  <FormikModalSelect
                    modalTitle={t('title.selectVatRate')}
                    modalData={getVatRateOptions(territory, t, values.type).map((item) => ({
                      name: item.label,
                      value: item.value,
                      description: '',
                    }))}
                    name="vatRate"
                    disabled={disabledFields.vatRate}
                    label={t('label.vat')}
                    placeholder={t('label.vat')}
                    data={getVatRateOptions(territory, t, values.type)}
                    onChange={(value) => {
                      setFieldValue('vatRate', value);

                      if (value !== Item_Vat_Rate.Exempt && value !== Item_Vat_Rate.Reversecharge) {
                        setFieldValue('vatExemptionReason', null);
                      } else {
                        setFieldValue('vatExemptionReason', null);
                        fetchTaxExemptionReasons({
                          variables: {
                            query: {
                              type: values.type,
                              vatRate: value,
                            },
                          },
                        });
                      }
                    }}
                    error={errors.vatRate && touched.vatRate ? errors.vatRate : null}
                  />
                </Group>

                {(values.vatRate === Item_Vat_Rate.Exempt || values.vatRate === Item_Vat_Rate.Reversecharge) && (
                  <Group grow align="start">
                    <FormikModalSelect
                      modalTitle={t('title.selectReason')}
                      modalData={getVatExemptionReasonExtendedOptions(
                        taxExemptionReasons?.taxExemptionReasons,
                        i18n.language
                      ).map((item) => ({
                        name: item.label,
                        value: item.value,
                        description: item.description,
                      }))}
                      name="vatExemptionReason"
                      disabled={disabledFields.vatExemptionReason || loadingTaxExemptionReasons}
                      placeholder={t('placeholder.vatExemptionReason')}
                      label={t('label.vatExemptionReason')}
                      data={getVatExemptionReasonOptions(taxExemptionReasons?.taxExemptionReasons)}
                      error={errors.vatExemptionReason && touched.vatExemptionReason ? errors.vatExemptionReason : null}
                    />
                  </Group>
                )}

                {allowQuantityField && (
                  <div className="w-[48%]">
                    <FormikNumberInput
                      name="quantity"
                      disabled={disabledFields.quantity}
                      allowNegative={false}
                      allowDecimal={false}
                      min={1}
                      label={t('label.quantity')}
                      error={(errors.quantity && touched.quantity ? errors.quantity : null) as React.ReactNode}
                    />
                  </div>
                )}

                {values.type === Item_Type.Service && (
                  <div className="pt-[12px]">
                    <WithholdingTaxForm
                      disabledWithholdingTaxAvailable={disabledFields.withholdingTaxAvailable}
                      disabledWithholdingTaxPercent={disabledFields.withholdingTaxPercent}
                    />
                  </div>
                )}
              </Stack>

              <Button
                type="submit"
                fullWidth
                onClick={async () => {
                  setIsFirstSubmitAttempt(true);
                  const formErrors = await validateForm();
                  scrollToErrors(formErrors);
                }}
                variant="primary"
                size="m"
                className="mt-[32px]"
              >
                {isEditing ? t('button.label.save') : t('button.label.create')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default CreateNewItemForm;

import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { Item_Vat_Rate } from '@/graphql-types';

import { INVOICE_ITEM_WITHHOLDING_TAX_TYPE } from '../types';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('validation.descriptionRequired'))
      .max(200, t('validation.descriptionTooLong'))
      .min(2, t('validation.descriptionTooShort')),
    type: Yup.string().required(t('validation.typeRequired')),
    unitPrice: Yup.number()
      .typeError(t('validation.unitPriceMustBeNumber'))
      .required(t('validation.unitPriceRequired'))
      .max(999999999.99, t('validation.unitPriceTooHigh'))
      .min(0.01, t('validation.unitPriceTooLow')),
    vatRate: Yup.string().required(t('validation.vatRateRequired')),
    vatExemptionReason: Yup.string()
      .when('vatRate', {
        is: (vatRate: Item_Vat_Rate) => [Item_Vat_Rate.Exempt, Item_Vat_Rate.Reversecharge].includes(vatRate),
        then: (schema) => schema.required(t('validation.vatExemptionReasonRequired')),
      })
      .nullable(),
    withholdingTaxAvailable: Yup.boolean(),
    withholdingTaxPercent: Yup.number().when('withholdingTaxAvailable', {
      is: true,
      then: (schema) =>
        schema
          .required(t('validation.withholdingTaxRequired'))
          .max(99, t('validation.withholdingTaxTooHigh'))
          .min(1, t('validation.withholdingTaxTooLow')),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    withholdingTaxReason: Yup.string().when(['withholdingTaxAvailable', 'withholdingTaxType'], {
      is: (available: boolean, type: INVOICE_ITEM_WITHHOLDING_TAX_TYPE) =>
        available && type === INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IS,
      then: (schema) =>
        schema
          .required(t('validation.withholdingTaxReasonRequired'))
          .max(60, t('validation.withholdingTaxReasonTooLong')),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  });

export default validationSchema;

import { useReactiveVar } from '@apollo/client';
import { Group, Switch, Text } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { IconPercentage } from '@tabler/icons-react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { CardBlock } from '@/components/common/CardBlock/CardBlock';
// import { FormikInput } from '@/components/FormikWrapped/new';
import { FormikNumberInput } from '@/components/FormikWrapped';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { Tenant_Type } from '@/graphql-types';

import { CreateNewItemFormProps, INVOICE_ITEM_WITHHOLDING_TAX_TYPE } from '../types';

type WithholdingTaxFormProps = {
  disabledWithholdingTaxAvailable?: boolean;
  disabledWithholdingTaxPercent?: boolean;
};

const WithholdingTaxForm = ({
  disabledWithholdingTaxAvailable,
  disabledWithholdingTaxPercent,
}: WithholdingTaxFormProps) => {
  const { setFieldValue, values } = useFormikContext<CreateNewItemFormProps>();
  const tenantObject = useReactiveVar(tenantVar);
  const formikProps = useFormikContext<CreateNewItemFormProps>();
  const withholdingTaxFieldName = 'withholdingTaxPercent';
  const [withholdingTaxField] = useField(withholdingTaxFieldName);
  const { scrollIntoView } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  const { t } = useTranslation();

  return (
    <CardBlock.Stack className="rounded-[20px] bg-angel-feather p-4">
      <Group justify="space-between">
        <Text fw={400} className="text-[17px]" lh={1} c="#3D3B36">
          {t('label.withholdingTax')}
        </Text>
        <Switch
          checked={values.withholdingTaxAvailable}
          size="md"
          disabled={disabledWithholdingTaxAvailable}
          color="#438BFA"
          onChange={(event) => {
            const isChecked = event.currentTarget.checked;
            setFieldValue('withholdingTaxAvailable', isChecked);
            if (!isChecked) {
              setFieldValue('withholdingTaxReason', undefined);
              setFieldValue('withholdingTaxPercent', undefined);
            } else {
              setFieldValue(
                'withholdingTaxType',
                tenantObject?.type === Tenant_Type.Individual
                  ? INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IRS
                  : INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IRC
              );
            }
          }}
        />
      </Group>

      {values.withholdingTaxAvailable && (
        <>
          {/* <SegmentedControl
            ref={targetRef}
            radius="14px"
            value={values.withholdingTaxType}
            data={[
              {
                label:
                  (tenantObject?.type ?? Tenant_Type.Individual) === Tenant_Type.Individual
                    ? `${t('label.irs')}`
                    : `${t('label.irc')}`,
                value:
                  tenantObject?.type === Tenant_Type.Individual
                    ? INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IRS
                    : INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IRC,
              },
              {
                label: t('label.stampDuty'),
                value: INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IS,
              },
            ]}
            className="max-w-[100%] bg-deep-white"
            onChange={(value) => {
              if (errors[withholdingTaxField.name as keyof typeof errors]) {
                setFieldError('withholdingTaxPercent', undefined);
                setFieldError('stampDuty', undefined);
              }
              setFieldValue('withholdingTaxType', value);
              setFieldValue('stampDuty', undefined);
              if (value !== INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IS) {
                setFieldValue('withholdingTaxReason', undefined);
              }
              if (value === INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IS && values.withholdingTaxReason) {
                setFieldValue('withholdingTaxReason', undefined);
              }
              if (handleTotalsOnDiscountChange) {
                handleTotalsOnDiscountChange(0);
              }
            }}
          /> */}
          <FormikNumberInput
            // id={withholdingTaxFieldName === 'withholdingTaxPercent' ? 'withholdingTaxPercent' : 'stampDuty'}
            id={withholdingTaxFieldName}
            name={withholdingTaxField.name}
            rightSection={
              <span className="mr-2.5">
                <IconPercentage width={16} height={16} />
              </span>
            }
            onFocus={() => scrollIntoView()}
            customClassNames={{
              input: 'h-14',
            }}
            allowNegative={false}
            clampBehavior="strict"
            inputMode="decimal"
            decimalScale={7}
            decimalSeparator=","
            thousandSeparator="."
            className="w-full"
            error={formikProps.errors.withholdingTaxPercent}
            disabled={disabledWithholdingTaxPercent}
          />
          {/* {values.withholdingTaxType === INVOICE_ITEM_WITHHOLDING_TAX_TYPE.IS && (
            <FormikInput
              label={t('label.reason')}
              name="withholdingTaxReason"
              placeholder={t('placeholder.withholdingTaxReason')}
              error={formikProps.errors.withholdingTaxReason}
            />
          )} */}
        </>
      )}
    </CardBlock.Stack>
  );
};

WithholdingTaxForm.displayName = 'WithholdingTaxForm';

export default WithholdingTaxForm;

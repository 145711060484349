import React, { useState } from 'react';
import { ComboboxItem, SelectProps, Select, Stack } from '@mantine/core';
import { useField, useFormikContext } from 'formik';
import classes from '../CustomInput.module.css';
import { cn } from '@/utils';
import CustomModal from '@/components/CustomModal';

interface FormikModalSelectProps extends SelectProps {
  name: string;
  data: { value: string; label: string }[];
  placeholder?: string;
  styles?: Record<string, string | number>;
  rest?: Record<string, unknown>;
  customClassNames?: Record<string, string>;
  modalTitle: string;
  modalDescription?: string;
  modalData: { name: string; description: string; value: string }[];
}

const FormikModalSelect = ({
  name,
  data,
  placeholder,
  label,
  styles,
  error,
  onChange,
  customClassNames,
  value,
  modalTitle,
  // modalDescription,
  modalData,
  ...rest
}: FormikModalSelectProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const [opened, setOpened] = useState(false);

  const handleOnChange = (value: string | null, option?: ComboboxItem) => {
    if (!value && !rest.clearable) return;

    if (onChange) {
      // @ts-expect-error
      onChange(value, option);
    } else {
      setFieldValue(name, value);
    }
  };

  const mergedClasses = {
    ...classes,
    input: cn(customClassNames, !value && !field.value ? classes.inputEmpty : classes.input),
  };

  return (
    <>
      <Select
        {...styles}
        dropdownOpened={false}
        value={value || field.value}
        placeholder={placeholder}
        label={field.value ? label : ''}
        data={data}
        id={name}
        onChange={handleOnChange}
        error={error}
        classNames={mergedClasses}
        size={(styles?.size as string) || 'md'}
        onClick={(e) => {
          e.preventDefault();
          setOpened(true);
        }}
        {...rest}
      />
      <CustomModal title={modalTitle} isOpened={opened} handleClose={() => setOpened(false)}>
        <Stack gap={12}>
          {modalData.map((item) => (
            <div
              key={item.value}
              className="cursor-pointer rounded-2xl bg-white-vanilla p-4 hover:bg-light-white-angel"
              onClick={() => {
                handleOnChange(item.value);
                setOpened(false);
              }}
            >
              <p className="text-[16px] font-semibold leading-[130%]">{item.name}</p>
              {!!item.description && (
                <p className="text-[14px] font-normal leading-[130%] text-gray-angel">{item.description}</p>
              )}
            </div>
          ))}
        </Stack>
      </CustomModal>
    </>
  );
};

export default FormikModalSelect;

import { ItemInput, MutationCreateItemArgs } from '@/graphql/types';

export type CreateNewItemFormProps = {
  defaultFormValues?: MutationCreateItemArgs['item'];
  style?: React.CSSProperties;
  onSuccess?: (newItem: ItemInput) => void;
  /** Pass together with updateItemId */
  isEditing?: boolean;
  updateItemId?: string;
  allowQuantityField?: boolean;
  isUpdatingInvoiceItem?: boolean;
  disabledFields?: {
    name?: boolean;
    taxRate?: boolean;
    unitType?: boolean;
    vatRate?: boolean;
    type?: boolean;
    vatExemptionReason?: boolean;
    quantity?: boolean;
  };
  shouldSaveToDB?: boolean;
  withholdingTaxPercent?: number;
  withholdingTaxType?: INVOICE_ITEM_WITHHOLDING_TAX_TYPE;
  withholdingTaxAvailable?: boolean;
  withholdingTaxReason?: string;
};

export enum INVOICE_ITEM_WITHHOLDING_TAX_TYPE {
  IRS = 'IRS',
  IRC = 'IRC',
  IS = 'IS',
}

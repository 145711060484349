import { TFunction } from '@/components/I18N';
import { Tenant_Pt_Territory, Item_Vat_Rate, Item_Type } from '@/graphql-types';

export const getVatRateOptions = (territory: Tenant_Pt_Territory, t: TFunction, type: Item_Type) => {
  const isService = type === Item_Type.Service;
  const exemptLabel = isService ? t('vat.rate.exemptServices') : t('vat.rate.exemptGoods');
  const noVatLabel = isService ? t('vat.rate.noVatServices') : t('vat.rate.noVatGoods');

  switch (territory) {
    case Tenant_Pt_Territory.Continental:
      return [
        { value: Item_Vat_Rate.Normal, label: `23% - ${t('vat.rate.normal')} ${t('vat.rate.continental')}` },
        {
          value: Item_Vat_Rate.Intermediate,
          label: `13% - ${t('vat.rate.intermediate')} ${t('vat.rate.continental')}`,
        },
        { value: Item_Vat_Rate.Reduced, label: `6% - ${t('vat.rate.reduced')} ${t('vat.rate.continental')}` },
        { value: Item_Vat_Rate.Reversecharge, label: `0% - ${noVatLabel}` },
        { value: Item_Vat_Rate.Exempt, label: `0% - ${exemptLabel}` },
      ];
    case Tenant_Pt_Territory.Madeira:
      return [
        { value: Item_Vat_Rate.Normal, label: `22% - ${t('vat.rate.normal')} ${t('vat.rate.madeira')}` },
        { value: Item_Vat_Rate.Intermediate, label: `12% - ${t('vat.rate.intermediate')} ${t('vat.rate.madeira')}` },
        { value: Item_Vat_Rate.Reduced, label: `5% - ${t('vat.rate.reduced')} ${t('vat.rate.madeira')}` },
        { value: Item_Vat_Rate.Reversecharge, label: `0% - ${noVatLabel}` },
        { value: Item_Vat_Rate.Exempt, label: `0% - ${exemptLabel}` },
      ];
    case Tenant_Pt_Territory.Azores:
      return [
        { value: Item_Vat_Rate.Normal, label: `16% - ${t('vat.rate.normal')} ${t('vat.rate.azores')}` },
        { value: Item_Vat_Rate.Intermediate, label: `9% - ${t('vat.rate.intermediate')} ${t('vat.rate.azores')}` },
        { value: Item_Vat_Rate.Reduced, label: `4% - ${t('vat.rate.reduced')} ${t('vat.rate.azores')}` },
        { value: Item_Vat_Rate.Reversecharge, label: `0% - ${noVatLabel}` },
        { value: Item_Vat_Rate.Exempt, label: `0% - ${exemptLabel}` },
      ];
    default:
      throw new Error('Invalid territory');
  }
};

export const getTaxRate = (territory: Tenant_Pt_Territory, vatRate: Item_Vat_Rate) => {
  if (territory === Tenant_Pt_Territory.Unknown) {
    throw new Error('Invalid territory');
  }

  if (vatRate === Item_Vat_Rate.Exempt || vatRate === Item_Vat_Rate.Reversecharge) {
    return 0;
  }

  return {
    [Tenant_Pt_Territory.Continental]: {
      [Item_Vat_Rate.Normal]: 23,
      [Item_Vat_Rate.Intermediate]: 13,
      [Item_Vat_Rate.Reduced]: 6,
    },
    [Tenant_Pt_Territory.Madeira]: {
      [Item_Vat_Rate.Normal]: 22,
      [Item_Vat_Rate.Intermediate]: 12,
      [Item_Vat_Rate.Reduced]: 5,
    },
    [Tenant_Pt_Territory.Azores]: {
      [Item_Vat_Rate.Normal]: 16,
      [Item_Vat_Rate.Intermediate]: 9,
      [Item_Vat_Rate.Reduced]: 4,
    },
  }[territory][vatRate];
};
